export function onLoading() {
  addTargetBlank();
}

function addTargetBlank() {

  console.log(`
  %cWebsite by Axel Durand%c (http://axeldurand.eu/)`,
    'font-family: Helvetica, sans-serif; font-size:20px; color:black;',
    'font-size: 10px;');

  const links = document.querySelectorAll("a");
  links.forEach((link) => {
    if (link.target) {
      return;
    } else if (link.host !== window.location.host) {
      link.target = "_blank";
      link.rel = "noopener";
    } else {
      link.target = "_self";
    }
  });
}
