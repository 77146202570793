let state;
let urlPage;
let urlHash = "";
let eventUrl = "";

const options = {
  rootMargin: '0px',
  threshold: 0.6
}

export function dynamicUrl() {
    // Change url
    
  const targets = document.querySelectorAll('.panel')

  if (targets) {
    let addUrl = (el) => {
    //history.replaceState({}, null, el.id);
      urlHash = '#' + el.id;
      setTimeout(function () {
        history.replaceState(state, urlPage, eventUrl + urlHash)
      }, 1500);
  }

  let doThings = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        addUrl(entry.target)
      }
      return;
    })
  }

    let observer = new IntersectionObserver(doThings, options)
    targets.forEach(target => {
      observer.observe(target)
    })

  }


}