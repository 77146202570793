import { dynamicUrl } from "./dynamicUrl";

export function conditionalFunctions() {

    if (window.location.pathname.indexOf("posters") > -1) {
        //console.warn("posters")
        dynamicUrl();
      }
      
      if (window.location.pathname.indexOf("exhibitions") > -1) {
        //console.warn("exh")
        dynamicUrl();
      }
    
}