import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';
import { dynamicUrl } from './dynamicUrl';
import { imageHover } from './HoverTextImages';
import { posterSwiper } from './Swiper';

let windowHeightSize = window.innerHeight * 2;


export function AjaxScroll() {
    
window.addEventListener('resize', function (event) {
  let windowHeightSize = window.innerHeight * 2;
});


let ias = new InfiniteAjaxScroll('.posters', {
  scrollContainer: '.posters',
  item: '.panel',
  next: '.pagination-next',
  pagination: '.pagination',
  negativeMargin: windowHeightSize,
  prefill: true
});

// Function de l'Infinite Scroll
function handler(event) {
  

  
    // Hash dynamic

    if (window.location.pathname === '/posters' || window.location.pathname === '/exhibitions') {

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        /* */
      }
    }

    dynamicUrl(); 
    posterSwiper();

    // Second intersection observer

    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          // console.log(entries)
          if (entry.isIntersecting) {
            entry.target.style.opacity = "1";
            observer.unobserve(entry.target);
          } else {
            //entry.target.style.opacity = "1";
          }
          return;
        });
      }, { rootMargin: "0px 0px 200px 0px" });

      document.querySelectorAll('.panel').forEach(panel => { observer.observe(panel) });
    }



    document.querySelectorAll(".panel").forEach((el) => {
      el.classList.add('scrollsnap');
    });

    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          let sections = document.querySelectorAll('.panel');
          imageHover();
    }



  }

  ias.on('load', function (event) {
    event.nocache = true; // clean url
  });

  ias.on('appended', handler);
  //ias.off('appended', handler);
  //ias.once('appended', handler);

  ias.on('page', (event) => {
    document.title = event.title;
    let state = history.state;
    let urlPage = event.title;
    let eventUrl = event.url;
    //history.replaceState(state, event.title, event.url + urlPage);

    /* let urlTitle = event.url + hash;
    setTimeout(function () {
      history.replaceState(state, event.title);
    }, 2000);
    */

  })


  ias.on('last', function () {
    let el = document.querySelector('.no-more');
    //el.style.opacity = '1';
    //console.log('last')
    //window.location.href = window.location.origin + "/posters";
  })



}