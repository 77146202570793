import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupDebugPlugin from '@swup/debug-plugin';

import { scrollContainer } from './scrollContainer';
import { init } from './Init';
import { removeLocationHash } from './removeLocationHash'


export function SwupTransition () {

const linkInclude = [
    `a[href^="${window.location.origin}"]`,
    `a[href^="${window.location}"]`,
    `a[href^="/"]`,
    `a[href^="#"]`,
    `a[href^="/page:*"]`,
    `a[href^="${window.location}/page:*"]`
  ]
  // Links to skip for swup navigation
  const linkExclude = [
    '[download]',
    '[target="_blank"]',
    `[href$=".pdf"]`
  ]
  
  const exclude = linkExclude.map(selector => `:not(${selector})`).join('')
  
  const swup = new Swup({
    linkSelector: linkInclude.map(include => `${include}${exclude}`).join(','),
    cache: false,
    animateHistoryBrowsing: true,
    plugins: [
      new SwupDebugPlugin({globalInstance: true}),
      new SwupScriptsPlugin({
        head: true,
        body: false
      }),
      new SwupProgressPlugin(),
    ]
  });



  swup.on('animationInDone', () => {
    if (window.location.href.indexOf("posters") != -1) {
    scrollContainer();
    console.warn('pos io')
      }

      if (window.location.href.indexOf("exhibitions") != -1) {
        scrollContainer();
        console.warn('exh io')
          }
   });


  swup.on('popState', () => {
      removeLocationHash();
   });

  
  // this event runs for every page view after initial load
  swup.on('contentReplaced', init);
  
  swup.on('transitionStart', function () {
    //$('body').addClass('inload');
    document.body.classList.add('inload')
    
  });
  
  swup.on('transitionEnd', function () {
    document.body.classList.remove('inload')
  });

  swup.on('popState	', init);


  function unload() {
    
    let isActive = document.querySelectorAll('.is-active');

    if (isActive) {
      isActive.forEach(element => {
        element.classList.remove('loaded')
      });
    }
    }

  swup.on('willReplaceContent', unload);

// Flèches retour / avant
window.addEventListener("popstate", (event) => {
  //history.go(-1);
  //location.reload();


const [entry] = performance.getEntriesByType("navigation");
console.table(entry.toJSON());

if (entry["type"] === "back_forward")
    location.reload();

});

}