import { FixElements } from './js/fix'
import { onLoading } from './js/website';
import { imageHover } from './js/HoverTextImages';
import { SwupTransition } from './js/Swup';
import { init } from './js/Init.js'
import { lazySizesParameters } from './js/lazySizes'
import { ButtonMenu } from './js/ButtonMenu';
import { dynamicUrl } from './js/dynamicUrl';
import { conditionalFunctions } from './js/conditionalFunctions';
import { ElementScroll } from './js/ScrollToElement';
import { scrollContainer } from './js/scrollContainer';
import { removeLocationHash } from './js/removeLocationHash';

import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
scrollSnapPolyfill()

/* <-- Fonctions--> */


document.addEventListener("DOMContentLoaded", () => {
    onLoading(); // Au chargement

    init();

    scrollContainer();
    removeLocationHash();

    setTimeout(dynamicUrl, 1500); // Changement de hash dynamique

    lazySizesParameters(); // Définit la taille des images

    ButtonMenu(); // Menu qui  blink

    conditionalFunctions(); // Fonctions qui s'appliquent à des pages spécifiques
    SwupTransition(); // Transitions swup
    imageHover(); // Cursor hover

});





