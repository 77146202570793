function FixVh () {

  const appHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  window.addEventListener('resize', appHeight)
}

function FixHeight () {


if (window.location.href.indexOf("posters") > -1) {

  const PosterHeight = () => {
    let PanelWidth = document.querySelector('.section__img');
    let width = PanelWidth.offsetWidth;
    let height = (width * 43) / 29;
    document.documentElement.style.setProperty('--posterheight', `${height}px`);
  }
  
    if (document.querySelector('.section__img')) {
      window.addEventListener('resize', PosterHeight)
      PosterHeight()
    }
  }
  return
}

function lazyBg() {
  //add simple support for background images:
document.addEventListener('lazybeforeunveil', function (e) {
  let bg = e.target.getAttribute('data-bg');
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')';
      }
    });
  return
}

export function FixElements() {
  FixVh();
  FixHeight();
  lazyBg();
}