const btn = document.querySelector('.hamburger--minus');
const menu = document.querySelector('#menu');
const hbg = document.querySelector('.hamburger--bg');
let halfWidth = window.innerWidth / 2;

export function ButtonMenu () {

    if (document.querySelector('.is-active')) {
      menu.classList.remove('show-menu');
      btn.classList.remove('is-active');
    }


    document.addEventListener('click', (event) => {
        const withinBoundaries = event.composedPath().includes(hbg) || event.composedPath().includes(btn)
      
        if (withinBoundaries) {
          menu.classList.toggle('show-menu');
          btn.classList.toggle('is-active');
          hbg.classList.toggle('reveal');
        } else {
          menu.classList.remove('show-menu');
          btn.classList.remove('is-active');
          hbg.classList.remove('reveal');
        }
      });
      
      
      document.addEventListener("mousemove", (event) => {
        let middlehalf = window.innerWidth / 2;
        if (event.clientX < middlehalf) {
          menu.classList.remove('show-menu');
          btn.classList.remove('is-active');
          hbg.classList.remove('reveal');
        }
      });

}