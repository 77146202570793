export function scrollContainer() {

    //if (window.location.hash) {
        const container = document.querySelector('.list__container');

        const urlHash = window.location.hash;
        const slicedUrl = urlHash.slice(1);
        const pageElement = document.getElementById(slicedUrl);
    
        if (container) {
            if (pageElement) {
            const pos = pageElement.getBoundingClientRect();
            container.scrollTo(0, pos.top + 29); 
            }
        }
    
       // scrollToElement(pageElement);
        console.log('📘 Hash is:', urlHash);
        console.log('📕 Selected element is:', pageElement);
    
        //}

}

