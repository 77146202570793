// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';

// Swiper poster

export function posterSwiper() {
const swiper = new Swiper('.swiper', {

            modules: [Navigation, Pagination],
            
            // Optional parameters
            speed: 600,
            loop: false,
            direction: 'horizontal',
            lazy: true,
            preloadImages: false,
            slidesPerView: 'auto',
            // Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            keyboard: {
              enabled: true,
              onlyInViewport: true
            },
      
            breakpoints: {
              300: {
                freeMode: false,
                grabCursor: true,
                spaceBetween: 5,
              },
              1000: {
                freeMode: false,
                grabCursor: false,
                spaceBetween: 5,
              }
            }
      
          });

}

export function exhibitionSwiper() {
          const exhswiper = new Swiper('.exhswiper', {

            modules: [Navigation, Pagination],

            // Optional parameters
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 5,
            lazy: true,
            preloadImages: false,
            speed: 600,
            loop: false,
            // Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
      
            keyboard: {
              enabled: true,
            },

            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
      
      
            breakpoints: {
              300: {
                freeMode: false,
                grabCursor: true,
                spaceBetween: 5,
              },
              1000: {
                freeMode: false,
                grabCursor: false,
                spaceBetween: 5,
              }
            },
      
          });      
}


export function processSwiper() {
const processswiper = new Swiper('.processswiper', {

  modules: [Navigation, Pagination],

  // Optional parameters
  direction: 'horizontal',
  slidesPerView: 'auto',
  spaceBetween: 5,
  lazy: true,
  preloadImages: false,
  speed: 600,
  loop: false,

  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },

  observer: true,
  observeParents: true,
  observeSlideChildren: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,

  breakpoints: {
    300: {
      freeMode: false,
      grabCursor: true,
      spaceBetween: 5,
    },
    1000: {
      freeMode: false,
      grabCursor: false,
      spaceBetween: 5,
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  on: {
    init: function () {
      let activeSlide = document.querySelector('.swiper-slide-active');
      let caption = activeSlide.dataset.caption;
      let captiondiv = document.querySelector('.captions')
      
      if (activeSlide.hasAttribute('data-caption')) {
        captiondiv.innerHTML = caption;
        console.log(caption);
      }
    },

    slideChangeTransitionStart: function () {
      let activeSlide = document.querySelector('.swiper-slide-active');
      let caption = activeSlide.dataset.caption;
      let captiondiv = document.querySelector('.captions')
      
      if (activeSlide.hasAttribute('data-caption')) {
        captiondiv.innerHTML = caption;
        console.log(caption);
      }
    },


  }
  });
}