import { imageHover } from './HoverTextImages';
import { dynamicUrl } from './dynamicUrl';
import { AjaxScroll } from './AjaxScroll';
import { FixElements } from './fix';
import { posterSwiper, exhibitionSwiper, processSwiper } from './Swiper';

/*
import $ from 'jquery';
window.$ = window.jQuery = $;
*/


// const container = document.querySelector('list__container')


export function init() {

    FixElements();

    if (window.location.pathname.indexOf("posters") != -1) {
      dynamicUrl()
    }
  
    if (window.location.pathname.indexOf("exhibitions") != -1) {
      dynamicUrl()
    }
  
    /*
  
    var scrolled = false;
    function scrollToElement(pageElement) {
      if (!scrolled) {
        while (pageElement != null) {
          const positionY = 0;
          const overflowY = container;
          positionY += pageElement.offsetTop;
          pageElement = pageElement.offsetParent;
          overflowY.scrollTop = positionY - 29;
          console.warn('scrolled yeah')
        }
      }
      scrolled = true;
    }
  
    if (window.location.hash) {

        let urlHash = window.location.hash.slice(1);
        let pageElement = document.getElementById(urlHash);

        console.log('IIII Selected element is:', pageElement);


      setTimeout(function () {
        if (pageElement) {
        const pos = pageElement.getBoundingClientRect();
        container.scrollTo(0, pos.top + 29); 
        }
       // scrollToElement(pageElement);
        console.log('📘 Hash is:', urlHash);
        console.log('📕 Selected element is:', pageElement);

      }, 1000);
    }
  
  */
    // Load More
  
    if (window.location.pathname == '/posters' || window.location.pathname == '/posters/page:1') {
        if ( document.querySelector('.menu__item')) {
            document.querySelector('.menu__item').remove();
        }

    }
  
  
    if (document.querySelector('.panel')) {
      document.querySelectorAll(".panel").forEach((el) => {
        el.classList.add('scrollsnap');
      });
    }
  
    // Burger
  
    if (document.querySelector('.is-active')) {
      menu.classList.remove('show-menu');
      btn.classList.remove('is-active');
    }
  

    // Menu
      
    let menuElements = document.querySelectorAll('#menu ul li');

    menuElements.forEach(function (item) {
        item.addEventListener('mouseenter',() => {
            item.classList.add('blink')
            });

        item.addEventListener('mouseleave',() => {
            item.classList.remove('blink')
            });
        });


    const hamburgerMinus = document.querySelector('.hamburger--minus')
    const hamburgerBg = document.querySelector('.hamburger--bg')

    hamburgerMinus.addEventListener('mouseenter',() => {
        hamburgerMinus.classList.add('blink')
        hamburgerBg.classList.add('blink')
        });

    hamburgerMinus.addEventListener('mouseleave',() => {
        hamburgerMinus.classList.remove('blink')
        hamburgerBg.classList.remove('blink')
        });






/*
    $("#menu ul li").on("mouseenter", function () {
      var ts = $(this);
      $(this).addClass("blink");
    });
  


    $("#menu ul li").on("mouseleave", function () {
      $(this).removeClass("blink");
    });


  
    $(".hamburger--minus").on("mouseenter", function () {
      var ts = $(this);
      $(this).addClass("blink");
      $('.hamburger--bg').addClass("blink");
    });
  
    $(".hamburger--minus").on("mouseleave", function () {
      $(this).removeClass("blink");
      $('.hamburger--bg').removeClass("blink");
    });

        */
  
    // Remove Hash automatically
  
    if (window.location.pathname == '/index' || window.location.pathname == '/process' || window.location.hostname) {
      // history.replaceState(null, null, ' ');
    }
  
    // Body Class
  
    document.body.classList.remove('bodyOverflow');
  
    if (document.querySelector('.posters')) {
      document.body.classList.add('bodyOverflow');
      // Transition fetch content
      const element = document.querySelector('.posters');
      const button = document.querySelector('.load-more');
      let page = parseInt(element.getAttribute('data-page'));
  
      const fetchProjects = async () => {
        let url = `${window.location.href}.json/page:${page}`;
        try {
          const response = await fetch(url);
          const { html, more } = await response.json();
          button.hidden = !more;
          element.innerHTML += html;
          page++;
        } catch (error) {
          console.log('Fetch error: ', error);
        }
      }
  
      //button.addEventListener('click', fetchProjects);
  

      AjaxScroll();
      
  
      
    }
  
  
  
    // Home reload
  
    /*
     
    const reload = document.querySelector('.reload');
     
    if (window.location.pathname == '/') {
        reload.onclick = location.reload.bind(location);
      }
     
      if (window.location.pathname != '/') {
        reload.onclick = undefined;
        }
      
      */
  
    // Swiper exhi
  
    if (window.location.pathname != '/posters' && window.location.pathname != '/exhibitions') {
      window.location.href.split('#')[0]
    }
  
    if (window.location.pathname == '/index') {
      document.body.classList.remove('bodyOverflow');
      document.body.classList.add('bodyOverflow');
    }
  
    if (window.location.pathname == '/about') {
      document.body.classList.remove('about');
      document.body.classList.add('about');
    }


    // Poster

    posterSwiper();
  
    if (window.location.pathname.indexOf("posters") != -1) {
      console.warn('posterswiper')
      posterSwiper();
    }

// Exhibition

    if (window.location.pathname.indexOf("exhibitions") != -1) {
  
      document.body.classList.remove('bodyOverflow');
      document.body.classList.add('bodyOverflow');
  
      exhibitionSwiper();
  
    }
  
  
    if (window.location.pathname.indexOf("process") != -1) {
      processSwiper();
    }
  
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      if (document.querySelector('.swiper-button-prev')) {
        document.querySelector('.swiper-button-prev').style.display = "block";
        document.querySelector('.swiper-button-next').style.display = "block";
      }
      imageHover();
    }




  }
  